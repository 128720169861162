import React, {useState} from "react";
import {navigate} from "gatsby";
import {Form, Button} from "react-bootstrap";
import {Logo, PageLayout, Markdown, FormGroup} from "../components/components";
import appContent from "../markdown/app-content";
import {clueMasterLogin} from "../services/firebase";
import useGameLogo from "../hooks/useGameLogo";

const IndexPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {smallLogo} = useGameLogo({useGameOnHomePage: true});

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    clueMasterLogin(email, password)
      .then(() => {
        navigate("/clue-master/games");
      })
      .catch(e => alert(e));
  };

  return (
    <PageLayout>
      <Logo src={smallLogo} style={{width: "100%"}}/>
      <Markdown>
        {appContent.cluemaster.login.markdown}
      </Markdown>
      <Form onSubmit={handleSubmit}>

        <FormGroup
          controlId="loginEmail"
          label={appContent.cluemaster.login.labels.email}
          subText={appContent.cluemaster.login.subText.email}
        >
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup
          controlId="loginPassword"
          label={appContent.cluemaster.login.labels.password}
          subText={appContent.cluemaster.login.subText.password}
        >
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </FormGroup>

        <Button variant="primary" type="submit">
          {appContent.cluemaster.login.buttonText}
        </Button>
      </Form>
    </PageLayout>
  );
};

export default IndexPage;
